<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.ware-info-report') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
        <b-row v-if="!authUser.warehouse_id">
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Division" vid="division_id" rules="">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-cols-md="4"
            :label="$t('warehouse_report.division')"
            label-for="division_id"
            slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_report.division')}}
            </template>
              <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Region" vid="region_id" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  :label="$t('warehouse_config.region')"
                  label-for="region_id"
                  slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('warehouse_config.region')}}
            </template>
                <b-form-select
                    plain
                    v-model="search.region_id"
                    :options="regionList"
                    id="division_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="District" vid="district_id" rules="">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-cols-md="4"
            :label="$t('warehouse_report.district')"
            label-for="district_id"
            slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('warehouse_report.district')}}
            </template>
              <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Upazila" vid="upazilla_id" rules="">
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('warehouse_report.upazilla')"
              label-for="upazilla_id"
              slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('warehouse_report.upazilla')}}
            </template>
              <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Union" vid="union_id" rules="">
            <b-form-group
              class="row"
               label-cols-sm="3"
               label-cols-md="4"
              :label="$t('globalTrans.union')"
              label-for="union_id"
              slot-scope="{ valid, errors }"
              >
              <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse Type" vid="warehouse_type_id" rules="">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_type_id"
                :label="$t('warehouse_config.warehouse_type')"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_config.warehouse_type')}}
                </template>
                <b-form-select
                plain
                v-model="search.warehouse_type_id"
                :options="warehouseTypeList"
                id="warehouse_type_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse" vid="warehouse_id" rules="">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              label-for="warehouse_id"
              :label="$t('warehouse_information.warehouse_name')"
              slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                    {{ $t('warehouse_information.warehouse_name') }}
            </template>
              <b-form-select
                plain
                v-model="search.warehouse_id"
                :options="warehouseList"
                id="commodity_group_id"
                v-if="!authUser.warehouse_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              >
              </b-form-select>
                <div v-if="!authUser.warehouse_id" class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="8">
                <b-button type="submit" variant="primary" class="float-right">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
            <b-col></b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.ware-info-report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Warehouse Information Report Sheet"
                  name="warehouse-information-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                    <!-- <b-col>
                        <div class="container">
                            <div>
                                <b-img width='80px' :src="warehouseServiceBaseUrl+organization.left_logo" fluid alt="Left Logo"></b-img>
                            </div>
                            <div class="main-title">
                                <div>
                                    <h5 class="org-name">{{ ($i18n.locale==='bn') ? organization.text_bn :  organization.text }}</h5>
                                </div>
                                <div>
                                    <h6 class="org-address">{{ ($i18n.locale==='bn') ? organization.address_bn :  organization.address }}</h6>
                                </div>
                                 <div>
                                    <h6 class="project-name">{{ ($i18n.locale==='bn') ? organization.project_name_bn :  organization.project_name }}</h6>
                                </div>
                                <div>
                                    <h5 class="report-name">{{ $t('warehouse_report.ware-info-report') }}</h5>
                                </div>
                            </div>
                            <div>
                                <p class="pull-left"><b-img width='80px' :src="warehouseServiceBaseUrl+organization.right_logo" fluid alt="Right Logo"></b-img></p>
                            </div>
                        </div>
                    </b-col> -->
                   <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.ware-info-report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="wareReportList" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_id)="data">
                      {{ data.item.division_id }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      {{ data.item.warehouse_name }}
                    </template>
                    <template v-slot:cell(total_capacity)="data">
                      <span> {{ $n(data.item.total_capacity) }}</span>
                    </template>
                    <template v-slot:cell(actual_capacity)="data">
                      <span>{{ $n(data.item.actual_capacity) }}</span>
                    </template>
                      <template v-slot:cell(free_space)="data">
                          <span>{{ $n(data.item.free_space) }}</span>
                      </template>
                      <template v-slot:cell(filled_space)="data">
                          <span>{{ $n(data.item.filled_space) }}</span>
                      </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, reportList } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  Division: 'division',
  District: 'district',
  Upazila: 'upazila',
  Warehouse: 'warehouse',
  'Total Size(Quintal)': 'totalSize',
  'Actual Size(Quintal)': 'actualSize',
  'Free Space(Quintal)': 'freeSpace',
  'Filled Space(Quintal)': 'filledSpace'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  বিভাগ: 'division',
  জেলা: 'district',
  উপজেলা: 'upazila',
  গুদাম: 'warehouse',
  'মোট পরিমাণ(কুইন্টাল)': 'totalSize',
  'প্রকৃত পরিমাণ(কুইন্টাল)': 'actualSize',
  'খালি জায়গা(কুইন্টাল)': 'freeSpace',
  'ভর্তি জায়গা(কুইন্টাল)': 'filledSpace'
}

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0
      },
      organization: [],
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      division_name: '',
      district_name: '',
      upazilla_name: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouseList: [],
      tempList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      districtLoading: false,
      searchHeaderData: {
        division_name_en: ''
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    json_fields: function () {
      return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    dataCustomizeExcel () {
      var serial = 0
      const customizeData = this.wareReportList.map(item => {
        serial += 1
        if (this.currentLocale === 'bn') {
          item.serial = this.$n(serial)
          item.division = item.division_name_bn
          item.district = item.district_name_bn
          item.upazila = item.upazilla_name_bn
          item.warehouse = item.warehouse_name_bn
          item.totalSize = this.$n(item.total_capacity)
          item.actualSize = this.$n(item.actual_capacity)
          item.freeSpace = this.$n(item.free_space)
          item.filledSpace = this.$n(item.filled_space)
        } else {
          item.serial = this.$n(serial)
          item.division = item.division_name
          item.district = item.district_name
          item.upazila = item.upazilla_name
          item.warehouse = item.warehouse_name
          item.totalSize = this.$n(item.total_capacity)
          item.actualSize = this.$n(item.actual_capacity)
          item.freeSpace = this.$n(item.free_space)
          item.filledSpace = this.$n(item.filled_space)
        }
        return Object.assign({}, item)
      })
      return customizeData
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + '  ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + '  ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + '  ' + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + '  ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + '  ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id)
      return [this.$t('warehouse_report.ware-info-report'), '', firstRow, secondRow, '']
    },
    regionList: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    },
    warehouseTypeList: function () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
        // if (this.isWareHouseUser) {
        //   typeList = typeList.filter(item => item.value === this.search.warehouse_type_id)
        // }
        // return typeList
    },
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return listObject
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('warehouse_report.division'), class: 'text-left' },
        { label: this.$t('warehouse_report.district'), class: 'text-left' },
        { label: this.$t('warehouse_report.upazilla'), class: 'text-left' },
        { label: this.$t('warehouse_report.warehouse_name'), class: 'text-left' },
        { label: this.$t('warehouse_report.ware_tot_size'), class: 'text-left' },
        { label: this.$t('warehouse_report.ware_actual_size'), class: 'text-left' },
        { label: this.$t('warehouse_report.ware_free_space'), class: 'text-left' },
        { label: this.$t('warehouse_report.ware_fill_space'), class: 'text-left' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'total_capacity' },
          { key: 'actual_capacity' },
          { key: 'free_space' },
          { key: 'filled_space' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'warehouse_name' },
          { key: 'total_capacity' },
          { key: 'actual_capacity' },
          { key: 'free_space' },
          { key: 'filled_space' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
            this.districtList = this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
       if (!this.isWareHouseUser) {
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
       }
    },
    'search.union_id': function (newVal, oldVal) {
         if (!this.isWareHouseUser) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
         }
    },
    'search.org_id': function (newVal, oldVal) {
      this.organization = this.organizationData(newVal)
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
         if (!this.isWareHouseUser) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
         }
    }
  },
  created () {
    this.loadData()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
        this.search.division_id = this.authUser.office_detail.division_id
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.districtList = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
         this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_type_id = warehouse.warehouse_type_id
       // this.warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(doc => doc.value === warehouse.warehouse_type_id)
    }
  },
  methods: {
    searchData () {
      if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.organizationData()
        this.showData = true
        this.showHeading = true
      }
      // this.loading = false
    },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      RestApi.getData(warehouseServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Organization not found!'
          })
        }
      })
    },
    loadData () {
      this.wareReportList = []
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      RestApi.getData(warehouseServiceBaseUrl, reportList, this.search).then(response => {
        if (response.success) {
          this.dataList(response)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
        // this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.wareReportList = []
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      })
    },
    dataList (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      const wareReportData = data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
        const divisionData = {
          division_name: divisionObject !== undefined ? divisionObject.text_en : '',
          division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: districtObject !== undefined ? districtObject.text_en : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
          upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData)
      })
      this.wareReportList = wareReportData
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
      this.districtLoading = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.division'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.district'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.upazilla'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.warehouse_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.ware_tot_size'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.ware_actual_size'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.ware_free_space'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.ware_fill_space'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Information Report' : 'গুদাম তথ্য রিপোর্ট'
      const columnWids = ['7%', '10%', '10%', '10%', '15%', '12%', '12%', '10%', '10%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: '', fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData, 'warehouse-information-report')
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'division_name' : 'division_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'district_name' : 'district_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'upazilla_name' : 'upazilla_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'warehouse_name' : 'warehouse_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'total_capacity' : 'total_capacity' },
          { key: this.$i18n.locale === 'en' ? 'actual_capacity' : 'actual_capacity' },
          { key: this.$i18n.locale === 'en' ? 'free_space' : 'free_space' },
          { key: this.$i18n.locale === 'en' ? 'filled_space' : 'filled_space' }
        ]
      var serial = 0
      const listData = this.wareReportList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'total_capacity') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'actual_capacity') {
              return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'free_space') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'filled_space') {
                return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })
        return rowData
      })

      return listData
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
